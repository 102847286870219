import { Box, Button, Container, Text,useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import PortfolioGrid from "../components/PortfolioGrid";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import IntroComponent2colWhite from "../components/IntroComponent2colWhite";
import SixPointsBanner from "../components/SixPointsBanner";

export default function Portfolio() {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });
  return (
    <>
     <Helmet>
        <title>tokentus | Portfolio</title>
        <meta name="description" content="We are very proud of our portfolio. Our investments have an excellent reputation and enormous potential in the blockchain market. Patience is important." />
        <link rel="canonical" href="https:/tokentus.com/portfolio" />
      </Helmet>
      <ImgTextModule
        src={"66406_road_winding_down_mountain_near_village_by_lake_aerial_by_Oliver_And_Tabea_Scholz_Artgrid-HD_H264-HD.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="95vh"
        mediaOpacity={0.9}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={{base:0,lg:'8'}}>
          <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontWeight="normal" fontSize="xl">
               Track record
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.green" fontWeight="normal"   fontSize={{ base: "5xl", lg: "6xl" }}>
                Our blockchain portfolio.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={24}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
                Disrupt classical business models with blockchain.
              </Text>
          
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

   <IntroComponent2colMonochrome 
      version="thirds" darktypo="green"
        headline="Our investments."
        text1="tokentus is a pure blockchain-focused and globally active venture capitalist."
        text2="We have evolved from a startup investor to a growth and later stage investor. Our 6 investment guidelines have been formed accordingly. Here are the companies that were able to convince us and that we were able to convince of us."
      />
     <PortfolioGrid />
    <Container bg="black"      
    variant="layoutContainer"
        mt={0.5} px={8}
       >
     <IntroComponent2colWhite
      version="thirds" darktypo={"dark"}
        headline="Blockchain hype versus reality."
        text1="We don't want to be blinded by the huge opportunities that the blockchain market promises."
        text2="As with the bursting of the dotcom bubble, not every company will survive and thrive in the blockchain market. Therefore we choose carefully."
      />
      </Container>
      <SixPointsBanner/>

      {/* CONTAINER QUADFEATURE */}
      <Container
        variant="layoutContainer"
        mt={0.5}
        mb={0.5}
        display="grid"
        gridGap={0.5}
        gridTemplateColumns={{
          lg: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
        
       <ImgTextModule
          minH="450px"
          w="100%"
          src={"lac4.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Investor Relations.
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  Information at your fingertips.
                </Text>
              </FadeInAnimation>
              <RouterLink  isExternal={false} link={'/investorrelations'}>

              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >
                Investor Relations
              </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
        
        <ImgTextModule
          minH="450px"
          w="100%"
          src={"header-master.mp4"}
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Experts.
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  From Seniority to Innovation. 
                </Text>
              </FadeInAnimation>
              <RouterLink  isExternal={false} link={'/team'}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >

                  
                Meet the team
              </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
        
      </Container>
     
    </>
  );
}
